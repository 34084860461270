import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";

import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { StarsCanvas } from "../canvas";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          {/* <Col md={8} className="home-about-description"> */}
          <div className="">
            <h3 style={{ fontSize: "2.6em" }}>
              Know More about <span className="purple"> Me </span>
            </h3>
            <p className="home-about-body text-left">
              I am an ambitious programmer with a computer science degree from
              Innopolis University specialized in cyber security, passionate
              about about innovation and eager to contribute to world-changing
              companies. <br />
              <br />I am the founder of
              <i>
                <b className="purple">
                  {" "}
                  <a
                    href="https://freemevpn.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-purple-600 hover:text-purple-700 no-underline"
                  >
                    FreeMeVPN{" "}
                  </a>
                </b>
              </i>
              A Free VPN service started as a small project in University times.
              Now expanding around the world with more servers and a new
              software.
              <br />
              <br />I am deeply fascinated by the development of
              <i>
                <b className="purple">Startups </b>, particularly those
                utilizing <b className="purple">Blockchain</b> technology.
                Additionally, I have a keen interest in the cryptocurrency space
                and I am continuously studying the trading market. My areas of
                interest include trading in Gold, Stocks, and Cryptocurrencies.
              </i>
              <br />
              <br />I Run a Marketing Agency <b className="purple">SAFI ADS </b>
              a boutique marketing agency where we specialize in providing
              comprehensive marketing services tailored to businesses and
              groundbreaking Crypto projects, including new tokens and NFTs.
              <br />
              <br />
              As a young entrepreneur at the threshold of my career, I am fueled
              by the ambition to launch a startup that transcends borders and
              makes a profound global impact.
              <br />
              <br />I am a seasoned amateur boxer, began my journey as a boxer
              at age 8, achieving significant success in local competitions. My
              dedication to the sport was highlighted at 14 when I won first
              place in the Syrian Boxing Competition for the lightweight class,
              lately my focus has shifted towards work while maintain my skills.
            </p>
          </div>
          {/* </Col> */}
          {/* <Col md={4} className="myAvtar">
            <Tilt>
              <img
                src={myImg}
                className="img-fluid hidden md:flex"
                alt="avatar"
              />
            </Tilt>
          </Col> */}
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h3 className="text-4xl">FIND ME ON</h3>
            <p></p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/MohamadSafi"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons "
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://x.com/ZRealSafi"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/mohamad-safi/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/mohmmed_safi/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <StarsCanvas />
    </Container>
  );
}
export default Home2;
